import Vue from 'vue';
export default new Vue({
  data() {
    return {};
  },
  methods: {
    toIOSAPP() {
      // 台湾zh-tw：https://apps.apple.com/tw/app/%E8%91%A9%E8%91%A9boom/id6449780357
      // 香港zh-hk：https://apps.apple.com/hk/app/%E8%91%A9%E8%91%A9boom/id6449780357
      // 澳门zh-mo：https://apps.apple.com/mo/app/%E8%91%A9%E8%91%A9boom/id6449780357

      let url = 'https://apps.apple.com/tw/app/%E8%91%A9%E8%91%A9boom/id6449780357';
      if (this.$store.current_lang == 'zh-hk') {
        url = 'https://apps.apple.com/hk/app/%E8%91%A9%E8%91%A9boom/id6449780357';
      } else if (this.$store.current_lang == 'zh-mo') {
        url = 'https://apps.apple.com/mo/app/%E8%91%A9%E8%91%A9boom/id6449780357';
      }
      window.open(url);
    },
    toGoogleAPP() {
      window.open('https://play.google.com/store/apps/details?id=net.chicchat.papa');
    },
  },
});
