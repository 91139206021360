<template>
  <div class="index-item">
    <div class="title-phone-box">
      <img src="@img/index/logo.png" class="img" />
      <div class="txt">{{ $t('content.logo_txt') }}</div>
    </div>
    <div class="app-list">
      <div class="logo-box">
        <img src="@img/index/logo.png" class="img" />
        <div class="txt">{{ $t('content.logo_txt') }}</div>
      </div>
      <div class="item"><img src="@img/index/google.png" class="img" @click="$store.toGoogleAPP()" /></div>
      <div class="item"><img src="@img/index/ios.png" class="img" @click="$store.toIOSAPP()" /></div>
      <!-- <div class="item"><img src="@img/index/android.png" class="img" /></div> -->
    </div>
    <div class="app-img">
      <img :src="imgSrc" class="img" />
    </div>
  </div>
</template>
<style lang="less" scoped>
.index-item {
  padding-right: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    padding-right: 0;
  }

  .title-phone-box {
    display: none;
    text-align: center;
    @media screen and (max-width: 820px) {
      display: block;
    }

    .img {
      width: 40vw;
      margin-bottom: 10px;
    }

    .txt {
      font-size: 16px;
      color: #393939;
      margin-bottom: -20px;
    }
  }

  // width: 900px;
  // height: 900px;
  .app-list {
    margin-right: 50px;

    @media screen and (max-width: 820px) {
      margin-right: 0;
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: absolute;
      bottom: -30px;
      width: 375px;
    }

    .logo-box {
      padding-bottom: 15px;

      @media screen and (max-width: 820px) {
        display: none;
      }

      .img {
        width: 200px;
      }

      .txt {
        font-size: 16px;
        color: #393939;
      }
    }

    .item {
      padding-bottom: 15px;

      @media screen and (max-width: 820px) {
        padding-bottom: 0;
        height: 50px;
      }

      .img {
        width: 140px;
        cursor: pointer;

        @media screen and (max-width: 820px) {
          width: 110px;
        }
      }
    }
  }

  .app-img {
    .img {
      width: 587px;
      height: 716px;

      @media screen and (max-width: 820px) {
        width: 90vw;
        height: 100vw;
      }
    }
  }
}
</style>
<script>
export default {
  props: {
    imgSrc: {
      type: String,
      default: require('@img/index/img1.png'),
    },
  },
};
</script>
