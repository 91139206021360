<template>
  <div class="tos-box">
    <a href="javascript:void(0)" class="tos-item" @click="goto('contact')">聯繫我們</a>
    <a href="javascript:void(0)" class="tos-item" @click="goto('termofservice')">服務條款</a>
    <a href="javascript:void(0)" class="tos-item" @click="goto('privacy')">隱私政策</a>
  </div>
</template>

<script>
export default {
  name: 'tosBox',
  methods: {
    goto(url = '') {
      // window.location.href = `https://activity17.ifreetalk.com/Announcement/template/view/${url}`;
      window.location.href = `https://papa.chicchat.net/${url}.html`;
    },
  },
};
</script>

<style lang="less" scoped></style>
