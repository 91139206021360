<template>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <IndexItem :img-src="img1" />
      </div>
      <div class="swiper-slide">
        <IndexItem :img-src="img2" />
      </div>
      <div class="swiper-slide">
        <IndexItem :img-src="img3" />
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
  <!-- <div>
    <swiper :options="swiperOption">
      <swiperSlide>
        <img :src="img1" class="img" />
      </swiperSlide>
      <swiperSlide>
        <img :src="img2" class="img" />
      </swiperSlide>
      <swiperSlide>
        <img :src="img3" class="img" />
      </swiperSlide>
      <div class="swiper-pagination"></div>
    </swiper>
  </div> -->
</template>
<style lang="less" scoped>
/deep/ .swiper-pagination-bullets {
  right: 260px;

  @media screen and (max-width: 820px) {
    right: 60px;
  }
}

/deep/ .swiper-pagination-bullet {
  background: #fff;
  opacity: 1;
}

/deep/ .swiper-pagination-bullet-active {
  background: #38ffd3;
}

.swiper-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: aqua;
  height: 900px;

  @media screen and (max-width: 820px) {
    height: 80vh;
  }
}
</style>
<script>
import Swiper from 'swiper';
// import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css';
// import 'swiper/dist/idangerous.swiper.css';

import IndexItem from './IndexItem.vue';

export default {
  props: {
    imgSrc: String,
  },
  components: {
    IndexItem,
    // swiper,
    // swiperSlide
  },
  data() {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
        },
      },
      img1: require('@img/index/img1.png'),
      img2: require('@img/index/img2.png'),
      img3: require('@img/index/img3.png'),
    };
  },
  mounted() {
    this.$nextTick(() => {
      new Swiper('.swiper-container', {
        loop: true,
        pagination: '.swiper-pagination',
        direction: 'vertical',
        slidesPerView: 1,
        paginationClickable: true,
        speed: 1000,
        mousewheelControl: true,
      });
    });
  },
};
</script>
