<style scoped lang="less">
.header {
  @media screen and (max-width: 820px) {
    background: rgba(255, 255, 255, 1);
    box-shadow: 3px 5px 6px 0px rgba(56, 68, 143, 0.06);
  }

  .header-inner {
    margin: 0 auto;
    padding: 12px 20px;
    max-width: 1240px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav-list {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .logo {
        width: 124px;
        margin-right: 87px;
      }

      .nav-item {
        position: relative;
        margin-right: 10px;
        font-family: PingFangTC-Medium, PingFangTC;
        font-size: 13px;
        font-weight: bold;
        text-decoration: none;
        color: #393939;

        &.router-link-exact-active {
          &::after {
            content: '';
            position: absolute;
            bottom: -4px;
            left: 0;
            width: 100%;
            height: 4px;
            border-radius: 2px;
            background-color: #38ffd3;
          }
        }
      }
    }

    .lang-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .btn-pay {
        width: 69px;
        height: 30px;
        background: url(~@img/index/btn-pay.png) no-repeat;
        background-size: cover;
      }

      .lang-info {
        position: relative;
        margin-left: 34px;

        @media screen and (max-width: 820px) {
          display: none;
        }

        &::after {
          position: absolute;
          right: -15px;
          top: 50%;
          margin-top: -2px;
          content: '';
          width: 0;
          height: 0;
          border-width: 5px;
          border-style: solid;
          border-color: #333 transparent transparent transparent;
        }

        &.active {
          .lang-list {
            display: block;
          }

          &::after {
            bottom: 50%;
            margin-bottom: -2px;
            top: unset;
            margin-top: unset;
            border-color: transparent transparent #333 transparent;
          }
        }

        .lang-list {
          display: none;
          position: absolute;
          top: 20px;
          right: -30px;
          width: 94px;
          height: auto;
          text-align: center;
          background: #fff;
          box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.1), -3px -1px 4px 0px rgba(85, 117, 148, 0.1);
          border-radius: 5px;

          .lang-item {
            padding: 8px 0;
            font-family: PingFangSC-Regular, PingFang SC;
            font-size: 12px;
            line-height: 17px;
            color: #717171;

            &.lang-item-active,
            &:hover {
              font-weight: bold;
              color: #000;
            }
          }
        }
      }

      .more-box {
        display: none;
        width: 20px;
        height: 16px;
        background: url(~@img/index_phone/more.png) no-repeat;
        background-size: 100% 100%;
        @media screen and (max-width: 820px) {
          margin-left: 11px;
          display: block;
        }
      }
    }
  }

  /deep/ .popup-box {
    height: 100%;
    width: 65%;

    .nav-list {
      padding: 34px 0 0 24px;
      font-size: 15px;
      font-weight: 400;
      color: rgba(57, 57, 57, 1);
      @navItemBottom: 22px;

      .nav-item {
        margin-bottom: @navItemBottom;
      }

      .h5-logo {
        width: 130px;
      }

      .h5-tos {
        display: flex;
        flex-flow: column;

        .tos-item {
          color: rgba(57, 57, 57, 1);
          margin-bottom: @navItemBottom;
        }
      }
    }
  }
}
</style>
<template>
  <div class="header">
    <div class="header-inner">
      <div class="nav-list">
        <img :src="$t('imgs.logo')" class="logo" />
        <!--        <router-link to="/" class="nav-item">{{ $t('menu.home') }}</router-link>-->
      </div>
      <div class="lang-box">
        <!--        <div class="btn-pay" />-->
        <div class="lang-info" :class="{ active: select_flag }" @click.stop="selectLang()">
          <div class="lang-txt">{{ current_lang.value }}</div>
          <div class="lang-list">
            <div class="lang-item" :class="{ 'lang-item-active': item.key == current_lang.key }" @click.stop="selectLang(item)" v-for="(item, index) in lang_list" :key="index">{{ item.value }}</div>
          </div>
        </div>
        <div class="more-box" @click.stop="openMore" />
      </div>
    </div>
    <van-popup v-model="is_show_modal" position="left" class="popup-box">
      <div class="nav-list">
        <div class="nav-item">
          <img :src="$t('imgs.logo')" class="h5-logo" />
        </div>
        <!--        <div class="nav-item">首頁</div>-->
        <TosBox class="h5-tos" />
      </div>
    </van-popup>
  </div>
</template>
<script>
import 'vant/lib/popup/style';
import Popup from 'vant/lib/popup';
import TosBox from '@components/tosBox.vue';

export default {
  components: { 'van-popup': Popup, TosBox },
  data() {
    return {
      select_flag: false,
      is_show_modal: false,
      current_lang: {
        key: 'zh-tw',
        value: '繁體中文',
      },
      lang_list: [
        {
          key: 'zh-tw',
          value: '繁體中文',
        },
      ],
    };
  },

  created() {
    let lang = localStorage.getItem('__language__');
    console.log('created: ', lang);
    if (lang) {
      this.lang_list.some((item) => {
        if (item.key == lang) {
          this.current_lang = item;
          return true;
        }
        return false;
      });
    }
  },
  methods: {
    openMore() {
      this.is_show_modal = !this.is_show_modal;
    },
    selectLang(info) {
      this.select_flag = !this.select_flag;
      if (info) {
        this.current_lang = info;
        localStorage.setItem('__language__', info.key);
        this.$i18n.locale = info.key;
      }
    },
  },
};
</script>
