<style lang="less" scoped>
#app {
  position: relative;
  height: 100vh;
  background-position: center;
  background-size: cover;
  user-select: none;
  background-image: url(~@img/index/bg.png);

  @media screen and (max-width: 820px) {
    background-image: url(~@img/index_phone/bg.png);
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 auto;
    max-width: 1200px;
    height: calc(100vh - 120px);
  }

  .footer {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 20px;
    max-width: 1200px;
    width: 100%;
    line-height: 20px;
    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC;

    @media screen and (max-width: 820px) {
      text-align: center;
    }

    .copyright {
      font-weight: 600;
      color: #fff;

      @media screen and (max-width: 820px) {
        margin: 0 auto;
      }
    }

    /deep/ .web-tos {
      display: flex;

      .tos-item {
        padding: 0 10px;
        color: #4782d9;
        font-weight: bold;
        text-decoration: none;
      }

      @media screen and (max-width: 820px) {
        display: none;
      }
    }

    .face-box {
      .img {
        width: 22px;
      }
    }
  }
}
</style>

<template>
  <div id="app">
    <Header />
    <div class="wrapper">
      <!-- 路由出口 -->
      <!-- 路由匹配到的组件将渲染在这里 -->
      <router-view />
    </div>
    <div class="footer">
      <div class="copyright">
        <!--        <div>郵箱：wangyuefeng@17paipai.cn</div>-->
        <div>Copyright © 2023- Chicchat Social Media Pte. Ltd.</div>
      </div>
      <!--服务条款-->
      <TosBox class="web-tos" />
      <!--      <div class="face-box">-->
      <!--        <img src="@img/index/facebook.png" class="img" />-->
      <!--      </div>-->
    </div>
  </div>
</template>
<script>
import Header from './components/common/Header.vue';
import TosBox from '@components/tosBox.vue';

export default {
  components: {
    Header,
    TosBox,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      // new Swiper('.swiper-container', {
      //   loop: true,
      //   // autoplay: 3000,
      //   direction: 'vertical',
      //   pagination: '.swiper-pagination',
      // });
    });
  },
  computed: {},
  methods: {
    /*
     *获取URL参数
     */
    getQueryString(name) {
      let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
      let r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return null;
    },
    //替换指定传入参数的值,paramName为参数,replaceWith为新值
    replaceParamVal(paramName, replaceWith) {
      let oUrl = window.location.href.toString();
      let re = eval('/(' + paramName + '=)([^&]*)/gi');
      let nUrl = oUrl.replace(re, paramName + '=' + replaceWith);
      return nUrl;
    },
  },
};
</script>
