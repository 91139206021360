if (process.env.NODE_ENV !== 'production') {
  require('mockjs');
  require('@/mock/mock');
}
import 'normalize.css';
import Vue from 'vue';
import App from './App';
import router from './router';
import i18n from './lang';

import store from './store/store';
// import h5ajax from 'h5ajax';

Vue.prototype.$store = store;
// Vue.prototype.$ajax = h5ajax;

Vue.config.productionTip = false;

new Vue({
  el: '#app',
  i18n,
  router,
  render: (h) => h(App),
  template: '<App/>',
});
