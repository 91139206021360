import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

import zhTW from './zh-TW';
let locale = localStorage.getItem('__language__') || window.navigator.language || window.navigator.userLanguage || 'zh-tw';
const i18n = new VueI18n({
  locale: locale.toLowerCase(),
  fallbackLocale: 'zh-tw', // 如果当前语种不存在时，默认设置当前语种
  messages: {
    'zh-tw': zhTW,
  },
});

export default i18n;
