export default {
  menu: {
    home: '首頁',
  },
  content: {
    logo_txt: '邊聊邊玩，整人我第一！',
  },
  imgs: {
    logo: require('@img/index/logo.png'),
  },
};
